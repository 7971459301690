import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

// Estado inicial del reducer
const oInitialState = {
    bLoading: false,
    nLoading: 0,
    sMessage: '',
    sMessageType: '',
}
/**
 * Reducer que sera utilizado para controlar todos los datos relacionados con los paquetes
 * 
 * @param {Array} oState Estado del reducer, si no recibe datos se setea el estado inicial
 * @param {object} oAction Action que es ejecutada, de aqui se obtienen los datos y el tipo de accion ejecutada
 * 
 * @returns {Array} Retorna un array que sera seteado en el estado del reducer
 * 
 * @author Leandro Curbelo 
 */
export const SharedReducer = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.MESSAGE:
            return { ...oState, bLoading: false, sMessage: oAction.sMessage, sMessageType: oAction.sMessageType };
        case CONSTANTS_ACTIONS.CLEAN_MESSAGE:
            return oInitialState;
        case CONSTANTS_ACTIONS.ACTIVE_LOADING:
            return { ...oState, bLoading: true, sMessage: '' };
        case CONSTANTS_ACTIONS.DESACTIVE_LOADING:
            return { ...oState, bLoading: false, sMessage: '' };
        default:
            return oState;
    }
}
