import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';

// Estado inicial del reducer
const oInitialState = {
    aPackages: null,
    bStatus: false,
}
/**
 * Reducer que sera utilizado para controlar todos los datos relacionados con los paquetes
 * 
 * @param {Array} oState Estado del reducer, si no recibe datos se setea el estado inicial
 * @param {object} oAction Action que es ejecutada, de aqui se obtienen los datos y el tipo de accion ejecutada
 * 
 * @returns {Array} Retorna un array que sera seteado en el estado del reducer
 * 
 * @author Leandro Curbelo 
 */
export const PackageReducer = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case CONSTANTS_ACTIONS.PACKAGES:
            return { ...oState, aPackages: oAction.data };
        case CONSTANTS_ACTIONS.PACKAGE_ADD:
            return { ...oState, bStatus: true };
        case CONSTANTS_ACTIONS.PACKAGE_CLEAN:
            return { ...oState, bStatus: false };
        default:
            return oState;
    }
}
