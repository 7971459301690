// Acciones globales que determinaran los valores de los reducers
export const CONSTANTS_ACTIONS = {
    // Actions para funciones compartidas o de un unico uso
    MESSAGE: 'MESSAGE',
    CLEAN_MESSAGE: 'CLEAN_MESSAGE',
    DESACTIVE_LOADING: 'DESACTIVE_LOADING',
    ACTIVE_LOADING: 'ACTIVE_LOADING',
    // Actions para el manejo de paquetes
    PACKAGES: 'PACKAGES',
    PACKAGE_ADD: 'PACKAGE_ADD',
    PACKAGE_CLEAN: 'PACKAGE_CLEAN',
}