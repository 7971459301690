import React from 'react';
import { connect } from 'react-redux';
import 'assets/css/SearchBar.css';
import { Form } from 'react-bootstrap';
import { CaretDown } from 'react-bootstrap-icons';
import { PRIMARY_COLOR } from 'constants/Colors';

function SearchBar(props) {
  return (
    <div className='row searchbar-container menu-shadow m-0'>
      <div className='searchbar-left-container vertical-align'>
        <Form.Control type='text' className='searchbar-search empty-border m-auto' placeholder='Buscar' />
      </div>
      <div className='searchbar-right-container row mx-0'>
        <div className='my-auto mx-1'>
          <CaretDown size={22} color={PRIMARY_COLOR} />
        </div>
        <div className='searchbar-profile-container my-auto mx-1'>
        </div>
        <div className='searchbar-profile-label my-auto mx-1'>Hi, <span className='text-pardelia'>Leandro</span></div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
