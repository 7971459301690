import LoadingTemplate from 'components/loading/LoadingTemplate';
import PackageItem from 'components/package/PackageItem';
import SearchBar from 'components/shared/SearchBar';
import React, { Component } from 'react';
import { Plus } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { actionGetPackages } from 'redux/actions/PackageActions';

class PackageListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    this.props.get();
  }

  handleChangeValue = (oEvent) => {
    let sValue = oEvent.target.value;
    if (oEvent.target.id === 'price')
      sValue = (sValue && sValue >= 0) ? sValue : 0;
    this.setState({ [oEvent.target.id]: sValue });
  }

  handleNavigateAdd = () => {
    this.props.history.push('/excursiones/nuevo');
  }

  render = () => {
    const { aPackages } = this.props.oPackageProps;
    return (
      <>
        <SearchBar />
        <div className='content'>
          <button className='btn-float-add btn-pardelia btn-done' onClick={this.handleNavigateAdd}>
            <Plus size={32} />
          </button>
          {
            aPackages !== null ?
              aPackages.map(oPackage => (
                <PackageItem oPackage={oPackage} key={oPackage.id} />
              ))
              :
              <LoadingTemplate />
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = oState => ({
  oPackageProps: oState.PackageReducer,
});

const mapDispatchToProps = dispatch => ({
  get: () => {
    dispatch(actionGetPackages())
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageListPage);
