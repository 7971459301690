import PackageCreatePage from 'pages/package/PackageCreatePage';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginPage from '../pages/login/LoginPage';
import { Package } from '../pages/package/Package';
import PackageListPage from '../pages/package/PackageListPage';

const routes = [
  {
    path: "/login",
    exact: true,
    component: LoginPage
  },
  {
    path: "/excursiones",
    component: Package,
    routes: [
      {
        path: "/excursiones/nuevo",
        component: PackageCreatePage
      },
      {
        path: "/excursiones",
        component: PackageListPage
      },
    ]
  },
];

export function RouteConfig() {
  return (
    <Switch>
      {routes.map((oRouter, nIndex) => (
        <RouteWithSubRoutes key={nIndex} {...oRouter} />
      ))}
    </Switch>
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}