import { Constants } from 'constants/Constants';
import oAxiosInstance from 'redux/interceptors/Interceptors';

const URL_PACKAGES = Constants.API_URL + 'packages';
/**
 * Servicio que obtiene los paquetes almacenados en el sistema
 * 
 * @param {number} nPage Pagina actual en la que se situa el usuario
 * 
 * @author Leandro Curbelo
 */
export const Get = (nPage) => {
    return oAxiosInstance.post(`${URL_PACKAGES}`, { page: nPage }).then(oResult => oResult.data)
}
/**
 * Servicio que almacena un nuevo paquete en el sistema
 * 
 * @param {object} oPackage Paquete con todos sus datos
 * 
 * @author Leandro Curbelo
 */
export const Add = (oPackage) => {
    return oAxiosInstance.post(`${URL_PACKAGES}/add`, { data: oPackage }).then(oResult => oResult.data)
}