import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Logo from 'assets/logo.png';
import 'assets/css/Menu.css';
import { BoxSeam, Inbox, List, People } from 'react-bootstrap-icons';
import { WHITE_COLOR } from 'constants/Colors';

class Sidebar extends Component {

  render = () => {
    return (
      true &&
      <div className='menu-shadow menu'>
        <div className='menu-item-logo vertical-align'>
          <img src={Logo} width='50' alt='Logo' />
        </div>
        <div className='menu-item-container'>
          <div className='align-top'>
            <NavLink className='menu-item' to='/inbox' exact activeClassName='menu-item-active'>
              <Inbox size={22} color={WHITE_COLOR} />
            </NavLink>
            <NavLink className='menu-item' to='/excursiones' activeClassName='menu-item-active'>
              <BoxSeam size={22} color={WHITE_COLOR} />
            </NavLink>
            <NavLink className='menu-item' to='/people' exact activeClassName='menu-item-active'>
              <People size={22} color={WHITE_COLOR} />
            </NavLink>
          </div>
          <div className='align-bottom'>
            <NavLink className='menu-item' to='/people' exact activeClassName='menu-item-active'>
              <List size={22} color={WHITE_COLOR} />
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
