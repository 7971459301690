import React, { Component } from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import { PRIMARY_COLOR } from 'constants/Colors';
import { CheckCircle, Circle, Paperclip, PlusCircle, Trash } from 'react-bootstrap-icons';
import { DATES_FORMAT } from 'constants/Formats';
import { actionMessage } from 'redux/actions/SharedActions';
import { actionAddPackage, actionCleanPackage } from 'redux/actions/PackageActions';
import SearchBar from 'components/shared/SearchBar';

let oMoment = require('moment');

class PackageCreatePage extends Component {
  constructor(props) {
    super(props);
    let dNow = oMoment(new Date());
    this.state = {
      sName: '',
      dStart: new Date(dNow.add({ months: 3 }).format(DATES_FORMAT.DATE_PICKER)),
      dFinish: new Date(dNow.add({ days: 10 }).format(DATES_FORMAT.DATE_PICKER)),
      nPlaces: '',
      nPrice: '',
      sDescription: '',
      sTag: '',
      sServiceName: '',
      nServicePrice: '',

      aServices: [],
      aTags: [],
    };
    // Se generan referencias a inputs
    this.oNameRef = React.createRef();
    this.oPlacesRef = React.createRef();
    this.oFinalPrice = React.createRef();
    this.oServiceNameRef = React.createRef();
    this.oDescriptionRef = React.createRef();
  }

  componentDidUpdate = (oProps) => {
    if (this.props.oPackageProps.bStatus) {
      this.props.history.push('/excursiones');
      this.props.clean();
    }
  }

  handleChangeValue = (oEvent) => {
    this.setState({ [oEvent.target.id]: oEvent.target.value });
  }

  handleChangeDate = (sName, dDate) => {
    let { dStart, dFinish } = this.state;
    if (sName === 'dStart') {
      if (dFinish < dDate)
        dFinish = dDate;
      dStart = dDate;
    } else {
      if (dStart > dDate)
        dStart = dDate;
      dFinish = dDate;
    }
    this.setState({ dStart: dStart, dFinish: dFinish });
  }

  handleAddService = () => {
    let { aServices, sServiceName, nServicePrice } = this.state;
    if (sServiceName === '' || nServicePrice === '')
      return;
    aServices.push({ name: sServiceName, price: nServicePrice, check: false });
    this.setState({
      aServices: aServices,
      sServiceName: '',
      nServicePrice: '',
    });
    this.oServiceNameRef.focus();
  }

  handleAddTag = (oEvent) => {
    // La siguiente actualizacion se realiza para refrescar el valor anterior del tag
    this.setState({ [oEvent.target.id]: oEvent.target.value, sTagOld: this.state.sTag });
    // Se toman las variables del estado del componente
    let { aTags, sTagOld, sTag } = this.state;
    // Si el evento que proviene es enter o blur se 
    if (oEvent.type === 'blur' || oEvent.key === 'Enter') {
      // Si el valor es vacio se ignora la ejecucion de la funcion, de lo contrario se agrega el nuevo tag y se actualiza el estado
      if (sTag.trim() !== '')
        this.setState({ aTags: [...aTags, { name: sTag }], sTag: '', sTagOld: '' });
      return;
    }
    // Si se oprime "Delete" y el anterior valor es vacio se elimina el ultimo tag de la lista y se actualiza el estado
    if (oEvent.key === 'Backspace' && sTagOld.trim() === '') {
      aTags.pop();
      this.setState({ aTags: aTags, sTag: '' });
    }
  }

  handleRemoveService = (nIndex) => {
    let { aServices } = this.state;
    aServices.splice(nIndex, 1);
    this.setState({ aServices: aServices });
  }

  handleRemoveTag = (nIndex) => {
    let { aTags } = this.state;
    aTags.splice(nIndex, 1);
    this.setState({ aTags: aTags });
  }

  handleCheckService = (nIndex, oService) => {
    oService.check = !oService.check;
    let { aServices } = this.state;
    aServices[nIndex] = oService;
    this.setState({ aServices: aServices });
  }

  handleCancel = () => {
    this.props.history.push('/excursiones');
  }

  handleSave = () => {
    const {
      sName,
      dStart,
      dFinish,
      nPlaces,
      nPrice,
      sDescription,
      aServices,
      aTags,
    } = this.state;
    let oPackage = {
      name: sName.trim(),
      start: oMoment(dStart).format(DATES_FORMAT.DATE_MYSQL),
      finish: oMoment(dFinish).format(DATES_FORMAT.DATE_MYSQL),
      places: nPlaces,
      price: nPrice,
      description: sDescription.trim(),
      services: aServices,
      tags: aTags,
    }
    let sError = this.validate(oPackage);
    if (!sError)
      return this.props.add(oPackage);
    this.props.message(sError);
  }

  validate = (oPackage) => {
    if (!oPackage.name || oPackage.name === '') {
      this.oNameRef.focus();
      return `La excursión debe tener un nombre`;
    }
    if (!oPackage.start || !oPackage.finish)
      return 'Se necesitan fechas para la excursión';
    if (!oPackage.places) {
      this.oPlacesRef.focus();
      return `Debe asignarse una cantidad de cupos`;
    }
    if (!oPackage.price) {
      this.oFinalPrice.focus();
      return `Debe asignarse un precio final`;
    }
    if (!oPackage.services || oPackage.services.length === 0) {
      this.oServiceNameRef.focus();
      return `Debe asignar servicios a su excursión`;
    }
    if (!oPackage.description) {
      this.oDescriptionRef.focus();
      return `Asigne una descipción a su excursión`;
    }
    return null;
  }

  render = () => {
    const {
      sName,
      dStart,
      dFinish,
      nPlaces,
      nPrice,
      sDescription,
      sTag,
      sServiceName,
      nServicePrice,

      aServices,
      aTags,
    } = this.state;
    return (
      <>
        <SearchBar />
        <div className='content'>
          <div className='item-container pl-4 p-3 my-4'>
            <Row>
              <Col md={12} className='my-2'>
                <label htmlFor='sName' className='field-title'>Nombre</label>
                <Form.Control type='text' placeholder='Asia 2022' id='sName' ref={(oRef) => this.oNameRef = oRef}
                  onChange={this.handleChangeValue} value={sName} className='empty-border' />
              </Col>
              <Col md={4} className='my-2'>
                <label htmlFor='places' className='field-title'>Fecha inicio</label>
                <DatePicker selected={dStart} onChange={dDate => this.handleChangeDate('dStart', dDate)}
                  dateFormat='dd/MM/yyyy' className='empty-border form-control' wrapperClassName='w-100' />
              </Col>
              <Col md={4} className='my-2'>
                <label htmlFor='places' className='field-title'>Fecha fin</label>
                <DatePicker selected={dFinish} onChange={dDate => this.handleChangeDate('dFinish', dDate)}
                  dateFormat='dd/MM/yyyy' className='empty-border form-control' wrapperClassName='w-100' />
              </Col>
              <Col md={2} className='my-2'>
                <label htmlFor='nPlaces' className='field-title'>Cupos</label>
                <Form.Control type='text' placeholder='20' id='nPlaces' ref={(oRef) => this.oPlacesRef = oRef}
                  onChange={this.handleChangeValue} value={nPlaces} className='empty-border' />
              </Col>
              <Col md={2} className='my-2'>
                <label htmlFor='nPrice' className='field-title'>Precio final</label>
                <Form.Control type='text' placeholder='USD' id='nPrice' ref={(oRef) => this.oFinalPrice = oRef}
                  onChange={this.handleChangeValue} value={nPrice} className='empty-border' />
              </Col>
              <Col md={12} className='mt-2'>
                <label className='field-title'>Servicios</label>
                {
                  aServices.length > 0 &&
                  aServices.map((oService, nIndex) => (
                    <div className='sub-item-container py-1' key={`service-${nIndex}`}>
                      <Col md={8} className='p-0'>
                        <span>{oService.name}</span>
                      </Col>
                      <Col md={2} className='p-0'>
                        <span>USD {oService.price}</span>
                      </Col>
                      <Col md={2} className='p-0 text-center'>
                        <Trash size={18} className='mx-2 animation-expand' color={PRIMARY_COLOR} onClick={() => this.handleRemoveService(nIndex)} />
                        {
                          oService.check ?
                            <OverlayTrigger placement='top'
                              overlay={
                                <Tooltip>
                                  Este servicio es un servicio adicional, click aquí para desmarcarlo
                            </Tooltip>
                              }>
                              <CheckCircle size={18} className='mx-2' color={PRIMARY_COLOR} onClick={() => this.handleCheckService(nIndex, oService)} />
                            </OverlayTrigger>
                            :
                            <OverlayTrigger placement='top'
                              overlay={
                                <Tooltip>
                                  Este servicio no es adicional, click aquí para marcarlo como adicional
                              </Tooltip>
                              }>
                              <Circle size={18} className='mx-2' color={PRIMARY_COLOR} onClick={() => this.handleCheckService(nIndex, oService)} />
                            </OverlayTrigger>
                        }
                      </Col>
                    </div>
                  ))
                }
              </Col>
              <Col md={12}>
                <div className='sub-item-container py-1'>
                  <Col md={8} className='p-0'>
                    <Form.Control id='sServiceName' type='text' placeholder='Agregar nuevo...'
                      onChange={this.handleChangeValue} ref={oRef => this.oServiceNameRef = oRef}
                      value={sServiceName} className='empty-border px-0' />
                  </Col>
                  <Col md={2} className='p-0'>
                    <Form.Control id='nServicePrice' type='number' placeholder='USD'
                      onChange={this.handleChangeValue}
                      value={nServicePrice} className='empty-border px-0'
                      onKeyPress={(oEvent) => { oEvent.key === 'Enter' && this.handleAddService() }} />
                  </Col>
                  <Col md={2} className='p-0 vertical-align horizotal-align' onClick={this.handleAddService}>
                    <PlusCircle size={18} color={PRIMARY_COLOR} className='animation-expand' />
                  </Col>
                </div>
              </Col>
              <Col md={12} className='my-2'>
                <label htmlFor='sTag' className='field-title'>Tags</label>
                <div className='tag-content row vertical-align'>
                  {
                    aTags.length > 0 &&
                    aTags.map((oTag, nIndex) => (
                      <span className={`tag col-tags ${nIndex !== 0 ? 'm-1' : 'my-1 mr-1'}`} key={`tag-${nIndex}`} onClick={() => this.handleRemoveTag(nIndex)}>{oTag.name}</span>
                    ))
                  }
                  <Form.Control type='text' placeholder='Asia' id='sTag' value={sTag} className='empty-border-all tag-input col'
                    onBlur={this.handleAddTag} onKeyUp={this.handleAddTag} onChange={this.handleChangeValue} />
                </div>
                <hr className='my-1' />
                {
                  aTags.length > 0 &&
                  <small className='text-muted'>Cliquea los tags para removerlos</small>
                }
              </Col>
            </Row>
          </div>
          <Row>
            <Col md={12} className='my-2'>
              <Form.Control id='sDescription' type='text' className='empty-border' placeholder='Descripción...'
                onChange={this.handleChangeValue} value={sDescription} rows={3} as='textarea'
                ref={oRef => this.oDescriptionRef = oRef} />
            </Col>
          </Row>
          <Row>
            <Col>
              <button className='btn-pardelia btn-done my-2 mr-2' onClick={this.handleSave}>Guardar</button>
              <button className='btn-pardelia btn-cancel m-2' onClick={this.handleCancel}>Cancel</button>
            </Col>
            <Col className='vertical-align align-right'>
              <Paperclip size={22} className='mr-3 attach' />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = oState => ({
  oPackageProps: oState.PackageReducer,
});

const mapDispatchToProps = dispatch => ({
  message: (sMessage) => {
    dispatch(actionMessage(sMessage));
  },
  add: (oPackage) => {
    dispatch(actionAddPackage(oPackage));
  },
  clean: () => {
    dispatch(actionCleanPackage());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageCreatePage);
