import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

function Body(props) {
  let history = useHistory();
  useEffect(() => {
    // history.push('/login');
    history.push('/excursiones');
  })
  return (
    <div className='my-3 my-md-3 mx-md-5'>
      <div className='container-fluid system-body'>
        {props.children}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Body);
