import React from 'react';
import 'assets/css/Loading.css';

export default function LoadingTemplate(props) {
    return (
        <>
            <div className='loading-template-container '>
                <div className='loading-template-item-lg'></div>
                <div className='loading-template-item-lg'></div>
                <div className='loading-template-item-lg'></div>
            </div>
            <div className='row'>
                <div className='loading-template-container-circles'>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                    <div className='loading-template-item-container-circle'><div className='loading-template-item-circle'></div></div>
                </div>
            </div>
        </>
    )
}