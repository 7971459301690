
import Axios from 'axios';
import promise from 'promise';
// import { actionLogout } from 'redux/actions/UserActions';
import { getTokenLocalStorange } from 'redux/services/StorageServices';
// import store from 'redux/Store';

var oAxiosInstance = Axios.create();

oAxiosInstance.interceptors.request.use(async function (oConfig) {
    var sToken = await getTokenLocalStorange();
    if (sToken)
        if (oConfig.method !== 'OPTIONS')
            oConfig.headers.Authorization = sToken;
    return oConfig;
}, function (oError) {
    return promise.reject(oError);
});

oAxiosInstance.interceptors.response.use(oResponse => {
    return promise.resolve(oResponse);
}, oError => {
    if (oError.response && oError.response.status === 401) {
        // store.dispatch(actionLogout());
        return;
    } else
        return promise.reject(oError.response);
});

export default oAxiosInstance;