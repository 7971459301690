import { CONSTANTS_ACTIONS } from 'constants/ConstantsActions';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { Add, Get } from 'redux/services/PackageServices';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage } from './SharedActions';

/**
 * Action que envia una peticion para obtener los paquetes almacenados en el sistema
 * 
 * @param {number} nPage Pagina actual en la que se situa el usuario
 * 
 * @author Leandro Curbelo
 */
export const actionGetPackages = (nPage = 0) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        Get(nPage).then(oSuccess => {
            setTimeout(() => {
                dispatch(actionDispatch(CONSTANTS_ACTIONS.PACKAGES, oSuccess.data));
            }, 1000);
        }).catch((oError) => {
            let sMessage = oError && oError.data ? oError.data.message : undefined;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
};
/**
 * Action que envia los datos de un nuevo paquete de excursión para ser almacenado
 * 
 * @param {object} oPackage Paquete con todos sus datos
 * 
 * @author Leandro Curbelo
 */
export const actionAddPackage = (oPackage) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        Add(oPackage).then(oSuccess => {
            setTimeout(() => {
                dispatch(actionDispatch(CONSTANTS_ACTIONS.PACKAGE_ADD));
                dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
            }, 2000);
        }).catch((oError) => {
            let sMessage = oError && oError.data ? oError.data.message : undefined;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
};
/**
 * Action que limpia el reducer de package
 * 
 * @author Leandro Curbelo
 */
export const actionCleanPackage = () => {
    return dispatch => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.PACKAGE_CLEAN));
    }
};