import React, { Component } from 'react';
import Sidebar from 'components/layout/Sidebar';
import Body from 'components/layout/Body';
import { RouteConfig } from 'router/Router';
import Loading from 'components/loading/Loading';
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from 'react-redux';
import Message from 'components/message/Message';
import { Container } from 'react-bootstrap';

class Layout extends Component {
  render() {
    return (
      <div>
        <Router>
          <Sidebar />
          <Container>
            <Body>
              <Container className='mt-5'>
                <RouteConfig />
              </Container>
            </Body>
          </Container>
        </Router>
        <Loading />
        <Message />
      </div>
    );
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout);