import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import 'pages/login/Login.css';
import { connect } from 'react-redux';
import Logo from'assets/logo.png';

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sEmail: '',
      sPassword: '',
      sErrorMessagePassword: '',
      sErrorMessageEmail: ''
    }
  }

  componentDidMount = async () => {
    // this.props.checkToken();
    await this.setState({
      sEmail: '',
      sPassword: ''
    });
  }

  componentDidUpdate = (oPrevProps) => {
    if (false) {
      this.setState({
        sEmail: '',
        sPassword: '',
      });
      this.props.history.push('/')
    }
  }

  login = async () => {
    await this.setState({
      sErrorMessagePassword: '',
      sErrorMessageEmail: '',
      sEmail: this.state.sEmail.trim(),
      sPassword: this.state.sPassword.trim()
    });
    if (this.state.sEmail !== '' && this.state.sPassword !== '') {
      this.props.login(this.state.sEmail, this.state.sPassword);
    } else {
      await this.setState({
        sErrorMessagePassword: this.state.sPassword === '' ? 'Contraseña requerida' : '',
        sErrorMessageEmail: this.state.sEmail === '' ? 'Email requerido' : '',
      });
    }
  }

  render() {
    return (
      <div className='all-screen row'>
        <div className='container-fluid login-container m-auto'>
          <Form>
            <div className='text-center'>
              <Row>
                <Col className="my-auto">
                  <h3 className='font-weight-bold'>Bienvenido</h3>
                </Col>
                <Col>
                  <img src={Logo} width="180" className="header-brand-img" alt="Logo" />
                </Col>
              </Row>
              <hr />
            </div>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email"
                onChange={(event) => { this.setState({ sEmail: event.target.value }) }}
                value={this.state.sEmail} />
              {
                this.state.sErrorMessageEmail !== '' &&
                <div className='error-message-form'>
                  {this.state.sErrorMessageEmail}
                </div>
              }
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type="password" placeholder="Contraseña"
                onChange={(event) => { this.setState({ sPassword: event.target.value }) }}
                value={this.state.sPassword} required />
              {
                this.state.sErrorMessagePassword !== '' &&
                <div className='error-message-form'>
                  {this.state.sErrorMessagePassword}
                </div>
              }
            </Form.Group>
            <div style={{ textAlign: 'right', marginTop: '40px' }}>
              <Button variant="primary" onClick={this.login}>Ingresar</Button>
            </div>
          </Form>
        </div >
      </div>
    );
  }
}

const mapStateToProps = state => ({
  oSession: state.sessionReducer,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);