import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

function Loading(props) {
  const { bLoading } = props.oShared
  return (
    bLoading ?
      <div className='overlay all-screen container-loading row'>
        <Spinner animation='border' role='status' className='m-auto spinner' />
      </div > : null
  )
}

const mapStateToProps = state => ({
  oShared: state.SharedReducer,
})

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
