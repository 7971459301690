import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { SharedReducer } from 'redux/reducers/SharedReducers';
import { PackageReducer } from 'redux/reducers/PackageReducers';

// Requerimiento para autor refresh de cache
const meta = require('meta.json');

const reducers = combineReducers({
    SharedReducer,
    PackageReducer,
});

const persistConfig = {
    key: meta.commitHash,
    version: meta.version,
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(persistedReducer, applyMiddleware(thunk));

export default store;
export const persistor = persistStore(store);