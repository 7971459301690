import { PRIMARY_COLOR } from 'constants/Colors';
import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { PlusCircle, ThreeDots, Trash } from 'react-bootstrap-icons';

/**
 * Funcion que renderiza un item de la lista de paquetes
 * 
 * @param {object} oProps Propiedades que recibe la funcion
 * 
 * @author Leandro Curbelo
 */
export default class PackageItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bOpen: false,
            sName: '',
            nPrice: '',
        };
        this.oExpandRef = React.createRef();
    }

    handleChangeOpen = () => {
        const { bOpen } = this.state;
        let sClass = bOpen ? 'close' : 'open';
        if (bOpen)
            this.oExpandRef.classList.toggle('open');
        else
            this.oExpandRef.classList.toggle('close');
        this.oExpandRef.classList.toggle(sClass);
        this.setState({ bOpen: !this.state.bOpen });
    }

    render = () => {
        const { oPackage } = this.props;
        const { bOpen, sName, nPrice } = this.state;
        return (
            <div className={`item-container pl-4 p-3 my-4`} key={oPackage.id}>
                <Row>
                    {
                        !bOpen ?
                            <>
                                <Col md={10}>
                                    <span className='item-title'>{oPackage.title}</span>
                                    <br />
                                    <span className='item-price text-pardelia'>USD {oPackage.price}</span>
                                    <br />
                                    <small className='item-short-description'>{oPackage.short_description}</small>
                                </Col>
                                <Col md={2} className='vertical-align'>
                                    <div className='item-plus-container' onClick={this.handleChangeOpen}>
                                        <ThreeDots size={14} color='white' className='p-auto' />
                                    </div>
                                </Col>
                            </>
                            :
                            <>
                                <Col md={6}>
                                    <span className='item-title'>{oPackage.title}</span>
                                    <br />
                                    <span className='item-price text-pardelia'>USD {oPackage.price}</span>
                                    <br />
                                    <small className='item-short-description'>{oPackage.short_description}</small>
                                </Col>
                                <Col md={6} className=' m-0' onClick={this.handleChangeOpen}>
                                    <div className='item-description text-pardelia'>
                                        Descripcion del viaje, pasa por Tokio y por todo el mundo por mundo
                                    </div>
                                </Col>
                            </>
                    }
                </Row>
                <div className='py-2'>
                    <div className='more-information close' ref={(oRef) => this.oExpandRef = oRef}>
                        {
                            oPackage.list.map(oElement => (
                                <div className='sub-item-container py-1' key={`${oPackage.id}-${oElement.id}`}>
                                    <Col md={9} className='p-0'>
                                        <span>{oElement.name}</span>
                                    </Col>
                                    <Col md={2} className='p-0'>
                                        <span>USD {oElement.price}</span>
                                    </Col>
                                    <Col md={1} className='p-0 vertical-align horizotal-align'>
                                        <Trash size={18} color={PRIMARY_COLOR} className='animation-expand' />
                                    </Col>
                                </div>
                            ))
                        }
                        <div className='sub-item-container py-1'>
                            <Col md={9} className='p-0'>
                                <Form.Control id='name' type='text' placeholder='Agregar nuevo...'
                                    onChange={(oEvent) => this.setState({ sName: oEvent.target.value })} value={sName} className='empty-border px-0' />
                            </Col>
                            <Col md={2} className='p-0'>
                                <Form.Control id='price' type='number' placeholder='USD'
                                    onChange={(oEvent) => this.setState({ nPrice: oEvent.target.value })} value={nPrice} className='empty-border px-0' />
                            </Col>
                            <Col md={1} className='p-0 vertical-align horizotal-align'>
                                <PlusCircle size={18} color={PRIMARY_COLOR} className='animation-expand' />
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}